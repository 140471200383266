import { Divider, Stack, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridCardHeader, SimpleBar } from "../../../../common/components";
import { useEffect, useState } from "react";
import { constants } from "../../../../config/constants";

const fakeData = [
    {
        hour_number: "0",
        total_sales: "1049.33",
    },
    {
        hour_number: "1",
        total_sales: "206.33",
    },
    {
        hour_number: "2",
        total_sales: "779.00",
    },
    {
        hour_number: "3",
        total_sales: "1427.00",
    },
    {
        hour_number: "4",
        total_sales: "1668.00",
    },
    {
        hour_number: "5",
        total_sales: "1196.00",
    },
    {
        hour_number: "6",
        total_sales: "831.65",
    },
    {
        hour_number: "7",
        total_sales: "541.31",
    },
    {
        hour_number: "8",
        total_sales: "159.98",
    },
    {
        hour_number: "9",
        total_sales: "2043.00",
    },
    {
        hour_number: "10",
        total_sales: "2150.00",
    },
    {
        hour_number: "11",
        total_sales: "1572.00",
    },
    {
        hour_number: "12",
        total_sales: "584.00",
    },
    {
        hour_number: "13",
        total_sales: "5589.00",
    },
    {
        hour_number: "14",
        total_sales: "1017.66",
    },
    {
        hour_number: "15",
        total_sales: "1037.00",
    },
    {
        hour_number: "16",
        total_sales: "2157.00",
    },
    {
        hour_number: "17",
        total_sales: "1702.00",
    },
    {
        hour_number: "18",
        total_sales: "1524.00",
    },
    {
        hour_number: "19",
        total_sales: "1634.00",
    },
    {
        hour_number: "20",
        total_sales: "2741.00",
    },
    {
        hour_number: "21",
        total_sales: "3800.00",
    },
    {
        hour_number: "22",
        total_sales: "5121.00",
    },
    {
        hour_number: "23",
        total_sales: "983.65",
    },
];

const TrendingHours = ({ data = fakeData }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [trendingHoursData, setTrendingHoursData] = useState([]);

    useEffect(() => {
        const refinedTrendingHours = refineTrendingHours(data || []);
        const refined_trending_hours = refinedTrendingHours.map((hourData) => ({
            name: constants.hours[`${hourData.hour_number}`],
            uv: hourData.total_sales,
        }));

        setTrendingHoursData(refined_trending_hours);
    }, []);

    const refineTrendingHours = (arr) => {
        const refined = [];
        for (let i = 0; i < 24; i++) {
            const hour = arr.find((item) => i === +item.hour_number);
            if (hour) refined.push(hour);
            else refined.push({ hour_number: i.toString(), total_sales: 0 });
        }

        return refined;
    };

    return (
        <Box sx={{ overflowX: "hidden", overflowY: "hidden" }}>
            <Stack minWidth="750px">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    padding={6}
                    height={74}
                >
                    <GridCardHeader title={t("overview.trending_hours")} />
                </Box>

                <Divider />

                <Box padding={6} paddingX={0} position="relative" top={20}>
                    <SimpleBar
                        data={trendingHoursData}
                        container={{ width: "100%", height: 320 }}
                        barSize={8}
                        margin={{
                            top: 0,
                            right: isArabic ? 35 : 25,
                            left: isArabic ? 25 : 35,
                            bottom: 0,
                        }}
                    />
                </Box>
            </Stack>
        </Box>
    );
};

export { TrendingHours };
