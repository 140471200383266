import { useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    List,
    ListItemButton,
    ListItemText,
    Popover,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const FilterSelect = ({ onChange, items, label, icon, value, filterList }) => {
    const InputIcon = icon;
    const [defaultAnchorEl, setDefaultAnchorEl] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selected, setSeleted] = useState(null);

    const [anchorNestedEl, setAnchorNestedEl] = useState(null);

    const handleClick = (event) => {
        if (!defaultAnchorEl) setDefaultAnchorEl(event.currentTarget);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const openNested = Boolean(anchorNestedEl);
    const id = open ? "simple-popover" : undefined;

    const handleItemClick = (e, selectedItem) => {
        handleClose();

        if (selectedItem?.subItems?.length) {
            setSeleted(selectedItem);
            setAnchorNestedEl(defaultAnchorEl);
        } else if (onChange) {
            setAnchorNestedEl(null);
            onChange({
                value: selectedItem.value,
                key: selected?.key || selectedItem?.key,
            });
            setSeleted(null);
        }
    };

    return (
        <Box>
            <Button
                onClick={handleClick}
                sx={{
                    minWidth: 140,
                    color: "black",
                    backgroundColor: "white",
                    border: "1px solid #EEEFF4",
                    "&:hover": {
                        border: "1px solid #EEEFF4",
                        backgroundColor: "white",
                    },
                    borderRadius: 4,
                    textTransform: "capitalize",
                    justifyContent: "space-between",
                }}
                endIcon={<InputIcon />}
                variant="outlined"
            >
                {label}
            </Button>

            {/* Main */}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                onClose={handleClose}
            >
                <Box sx={{ pointerEvents: "auto" }}>
                    <List sx={{ width: "150px", paddingY: 2 }}>
                        {items.map((item) => (
                            <ListItemButton
                                disabled={item.isDisabled}
                                sx={{ height: 32 }}
                                onClick={(e) => handleItemClick(e, item)}
                                key={item.value}
                            >
                                <ListItemText
                                    primaryTypographyProps={{
                                        fontSize: 14,
                                    }}
                                    primary={item.label}
                                />

                                {value === item.value && (
                                    <CheckIcon
                                        sx={{ color: "#cccccc", fontSize: 16 }}
                                    />
                                )}
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
            </Popover>

            {/* Nested */}
            <Popover
                id={id}
                open={openNested}
                anchorEl={anchorNestedEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                onClose={() => setAnchorNestedEl(null)}
            >
                <Box sx={{ pointerEvents: "auto" }}>
                    <List sx={{ width: "150px", padding: 2 }}>
                        <FormGroup>
                            {selected?.subItems?.map((classItem) => {
                                const isChecked = filterList?.some(
                                    (filter) => filter.value === classItem
                                );
                                return (
                                    <FormControlLabel
                                        key={classItem}
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={isChecked}
                                            />
                                        }
                                        label={classItem}
                                        onClick={(e) =>
                                            handleItemClick(e, {
                                                value: classItem,
                                            })
                                        }
                                    />
                                );
                            })}
                        </FormGroup>
                    </List>
                </Box>
            </Popover>
        </Box>
    );
};

export { FilterSelect };
