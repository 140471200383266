import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { FilterSelect, RangePicker } from "../../../../common/components";
import { services } from "../../services";
import { FrequentItems } from "./FrquentItems";
import { getToken, getUserStorage } from "../../../../common/utils";
import { constants } from "../../../../config/constants";
import { toast } from "react-toastify";
import { ExportButton } from "../../../../common/components/Export/Export";

const BasketFreq = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const [period, setPeriod] = useState({
        startDate: null,
        endDate: format(new Date(), "y-M-d"),
    });

    const filterItems = [
        { label: t("common.dine_in"), value: "DINING", key: "type" },
        { label: t("common.pickup"), value: "TAKE_AWAY", key: "type" },
        {
            label: t("common.drive_through"),
            value: "DRIVE_THROUGH",
            key: "type",
        },
        { label: t("common.delivery"), value: "DELIVERY", key: "type" },
    ];
    const [orderType, setOrderType] = useState(filterItems[0].value);

    const [freqProducts, setFreqProducts] = useState([]);
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const [overviewPDF, setOverviewPDF] = useState(null);

    const { mutate: getMostFrequent, isLoading } = useMutation({
        mutationFn: services.getMostFrequent,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                const response = res.data.response;

                const freq_products = response.map((list) => {
                    const arr = [];

                    // todo: workaround!
                    if (list.item1_)
                        arr.push({ name: list.item1_, img: list.image1_ });
                    if (list.item2_)
                        arr.push({ name: list.item2_, img: list.image2_ });
                    if (list.item3_)
                        arr.push({ name: list.item3_, img: list.image3_ });
                    if (list.item4_)
                        arr.push({ name: list.item4_, img: list.image4_ });
                    if (list.item5_)
                        arr.push({ name: list.item5_, img: list.image5_ });

                    return { total: list.total_count, recommends: arr };
                });

                setFreqProducts(freq_products);
                setOverviewPDF({
                    freq_products,
                    period,
                });
            }

            if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    const handleDatesChange = ({ startDate, endDate }) => {
        if (startDate !== "1970-1-1") {
            setPeriod({ startDate, endDate });
            getMostFrequent({
                from_date: startDate,
                till_date: endDate,
                order_type: orderType,
            });
        }
    };

    const handleFilterChange = (value) => {
        setOrderType(value);
        getMostFrequent({
            from_date: period.startDate,
            till_date: period.endDate,
            order_type: value,
        });
    };

    useEffect(() => {
        if (period.startDate) {
            getMostFrequent({
                from_date: period.startDate,
                till_date: period.endDate,
                order_type: orderType,
            });
        }
    }, []);

    useEffect(() => {
        window.pendo.pageLoad({
            name: "Basket Bought together Page",
        });
    }, []);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Grid container flexWrap="nowrap">
                <Grid item xs={6} marginRight={8}>
                    <Stack spacing={0}>
                        <Typography variant="h4">{t("basket.freq")}</Typography>
                    </Stack>
                </Grid>
            </Grid>

            <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center" gap={3}>
                    <RangePicker
                        onChange={(value) => handleDatesChange(value)}
                    />

                    {isLoading && (
                        <CircularProgress color="primary" size={20} />
                    )}
                </Box>

                <Box display="flex" alignItems="center" gap={4}>
                    <FilterSelect
                        value={orderType}
                        label={t(`common.${orderType}`)}
                        // label={t("common.order_type")}
                        items={filterItems}
                        onChange={({ value }) => handleFilterChange(value)}
                        icon={KeyboardArrowDownIcon}
                    />

                    <ExportButton
                        overviewPDF={overviewPDF}
                        pageName="BasketFreqPDF"
                        isLoading={isLoading}
                    />

                    {/* <IconButton
                        color="primary"
                        aria-label="add to shopping cart"
                    >
                        <IosShareIcon />
                    </IconButton> */}
                </Box>
            </Box>

            <Box>
                <Typography variant="h5">
                    {t("basket.products_freq")}
                </Typography>
            </Box>

            {!freqProducts.length && (
                <Box padding={40}>
                    <Typography variant="body1" align="center">
                        {t("common.empty")}
                    </Typography>
                </Box>
            )}

            {freqProducts.map((prodcutsList, index) => (
                <FrequentItems
                    title={`${t("basket.top")} ${index + 1}`}
                    items={prodcutsList.recommends}
                    total={prodcutsList.total}
                />
            ))}
        </Stack>
    );
};

export { BasketFreq };
