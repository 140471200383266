import { Box, Divider, Stack, Typography } from "@mui/material";
import { VictoryLabel, VictoryPie } from "victory";
import { useTranslation } from "react-i18next";
import {
    GridCardFlatLabels,
    GridCardLabels,
} from "../../../../common/components";

import { constants } from "../../../../config/constants";
import { useEffect, useState } from "react";

const fakeData = [
    {
        order_type: "Dine In",
        total_sales: "27463.91",
    },
    {
        order_type: "Drive Thru",
        total_sales: "8189.00",
    },
    {
        order_type: "Pick Up",
        total_sales: "11161.00",
    },
    {
        order_type: "Delivery",
        total_sales: "961.00",
    },
];

const OrderTypes = ({ data = fakeData, pieContainerStyles }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [orderTypesData, setOrderTypesData] = useState([]);

    const total = data?.reduce((acc, datum) => acc + datum.value, 0);

    useEffect(() => {
        const orderTypesTotal = getTotalCount(data);
        setOrderTypesData(
            data.map((order, index) => ({
                name: order.order_type,
                value:
                    Math.floor(
                        (order.total_sales / orderTypesTotal).toFixed(2) * 1000
                    ) / 10,
                fill: constants.pieColors[index],
                innerRadius: (index + 1) * 10,
                outerRadius: 100 + (index + 1) * 12,
            }))
        );
    }, []);

    function getTotalCount(arr, key = "total_sales") {
        let count = 0;
        arr.forEach((i) => (count = count + +i[key]));

        return count;
    }

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <Typography fontWeight="bold" fontSize={20} color="#202020">
                    {t("sales.order_type")}
                </Typography>
            </Box>

            <Divider />

            <Stack>
                <Box width="fit-content" margin="auto">
                    <GridCardFlatLabels data={orderTypesData} isFlat />
                </Box>

                <Box
                    margin="auto"
                    width="400px"
                    height="100%"
                    pr={10}
                    position="relative"
                    {...pieContainerStyles}
                >
                    <svg
                        viewBox="0 0 400 350"
                        style={{
                            width: "100%",
                            height: "80%",
                            padding: "0",
                            margin: "0",
                        }}
                    >
                        <VictoryPie
                            standalone={false}
                            x="name"
                            y="value"
                            data={orderTypesData}
                            colorScale={constants.pieColors}
                            radius={({ datum }) => datum.outerRadius}
                            labelRadius={50}
                            labelComponent={
                                <VictoryLabel
                                    style={{
                                        fill: "white",
                                        fontSize: "18px",
                                    }}
                                />
                            }
                            // labels={({ datum }) =>
                            //     `${((datum.value / total) * 100).toFixed(0)}%`
                            // }
                            labels={() => null}
                        />
                    </svg>
                </Box>
            </Stack>
        </Stack>
    );
};

export { OrderTypes };
