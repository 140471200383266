import { useEffect, useState } from "react";
import {
    Autocomplete,
    Box,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DoughnutChart, GridCard } from "../../../../common/components";
import { constants } from "../../../../config/constants";
import { PerformanceLineChart } from "../Components/PerformanceChart";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { services } from "../../services";
import { format } from "date-fns";
import { getUserStorage } from "../../../../common/utils";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";
import { uniqBy } from "lodash";
import { VerticalBarChart } from "../../Overview/components/VerticalBarChart";
import { NewVerticalBarChart } from "../../Overview/components/NewVerticalChart";

const SalesCard = ({ label, value }) => {
    const { t, i18n } = useTranslation();

    return (
        <Stack
            padding={8}
            spacing={4}
            borderRadius={2}
            backgroundColor="white"
            boxShadow="0 1px 3px hsla(220,6%,43%,0.2)"
        >
            <Typography variant="body1">{label}</Typography>
            <Typography variant="body1">
                {+value?.toFixed(2)} {t("common.currency")}
            </Typography>
        </Stack>
    );
};

const SalesCardWithBranchs = ({ label, total, chartData, containerProps }) => {
    const { t } = useTranslation();

    return (
        <Stack width="50%" p={4} {...containerProps}>
            <Stack spacing={2}>
                <Typography fontSize="16px">{label}</Typography>
                <Typography fontSize="26px">
                    {+total?.toFixed(2)} {t("common.currency")}
                </Typography>
            </Stack>

            {/* <Box width="100%" height="300px">
                <NewVerticalBarChart height="200px" data={chartData} />
            </Box> */}
        </Stack>
    );
};

const BranchDetails = ({ allBranches, label, gutter }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const user = getUserStorage();
    const isOwner = user.role === 1;

    const [selectedBranch, setSelectedBranch] = useState(null);
    const [salesData, setSalesData] = useState([]);
    const [salesPeriods, setSalesPeriods] = useState({});
    const [branchesSalesPeriods, setBranchesSalesPeriods] = useState({
        today: {
            total: 0,
        },
        prevWeek: {
            total: 0,
            chart: [],
        },
        prevMonth: {
            total: 0,
            chart: [],
        },
        prevQuarter: {
            total: 0,
            chart: [],
        },
        prevYear: {
            total: 0,
            chart: [],
        },
    });
    const [branches, setBranches] = useState([]);

    const { mutate: getBranchLikeToLike, isLoading } = useMutation({
        mutationFn: services.getBranchLikeToLike,
        onSuccess: (res) => {
            if (res.data.response) {
                const response = res.data.response;

                setSalesData(
                    response.sales_branch_like_to_like.map((i) => ({
                        name: i.closed_date,
                        "total sales": i.total_sales,
                        date: i.closed_date,
                    }))
                );

                if (!isOwner) setSalesPeriods(response.branch_like_to_like);
                else {
                    setBranches(
                        response.branch_like_to_like?.map((branch, index) => ({
                            name: branch.branch_name,
                            fill: constants.pieColors[index],
                        }))
                    );
                    refineBranchesSales(response.branch_like_to_like);
                }
            }
        },
        onError: (err) => console.log(err),
    });

    const handleBranchSelect = (newSelectedBranch) => {
        setSelectedBranch(newSelectedBranch);

        getBranchLikeToLike({
            branch_id: newSelectedBranch.id,
            date: format(new Date(), "y-M-d"),
        });
    };

    const highestTotalSales = roundToNearest10(
        getHighestValue(salesData, "total sales")
    );

    function refineBranchesSales(branchesSalesArr) {
        const totalCounts = {
            today: getTotalCount(branchesSalesArr, "current date"),
            prevWeek: getTotalCount(branchesSalesArr, "previous week"),
            prevQuarter: getTotalCount(branchesSalesArr, "previous quarter"),
            prevMonth: getTotalCount(branchesSalesArr, "previous month"),
            prevYear: getTotalCount(branchesSalesArr, "previous year"),
        };
        const branchesPerc = {
            prevWeek: [],
            prevQuarter: [],
            prevMonth: [],
            prevYear: [],
        };

        branchesSalesArr.forEach((branch, index) => {
            if (branch["previous week"])
                branchesPerc.prevWeek.push({
                    name: branch.branch_name,
                    value:
                        (+branch["previous week"] / totalCounts.prevWeek) * 100,
                    fill: constants.pieColors[index],
                });
            if (branch["previous quarter"])
                branchesPerc.prevQuarter.push({
                    name: branch.branch_name,
                    value:
                        (+branch["previous quarter"] /
                            totalCounts.prevQuarter) *
                        100,
                    fill: constants.pieColors[index],
                });
            if (branch["previous month"])
                branchesPerc.prevMonth.push({
                    name: branch.branch_name,
                    value:
                        (+branch["previous month"] / totalCounts.prevMonth) *
                        100,
                    fill: constants.pieColors[index],
                });
            if (branch["previous year"])
                branchesPerc.prevYear.push({
                    name: branch.branch_name,
                    value:
                        (+branch["previous year"] / totalCounts.prevYear) * 100,
                    fill: constants.pieColors[index],
                });
        });

        setBranchesSalesPeriods({
            today: totalCounts.today,
            prevWeek: {
                total: totalCounts.prevWeek,
                chart: uniqBy(branchesPerc.prevWeek, (b) => b.fill),
            },
            prevQuarter: {
                total: totalCounts.prevQuarter,
                chart: uniqBy(branchesPerc.prevQuarter, (b) => b.fill),
            },
            prevMonth: {
                total: totalCounts.prevMonth,
                chart: uniqBy(branchesPerc.prevMonth, (b) => b.fill),
            },
            prevYear: {
                total: totalCounts.prevYear,
                chart: uniqBy(branchesPerc.prevYear, (b) => b.fill),
            },
        });
    }

    function roundToNearest10(number) {
        return Math.ceil(number / 10) * 10;
    }

    function getHighestValue(data, key) {
        let highestValue = 0;

        for (const item of data) {
            const value = parseFloat(item[key]);
            if (value > highestValue) {
                highestValue = value;
            }
        }

        return highestValue;
    }

    function getTotalCount(arr, key = "total_sales") {
        let count = 0;
        arr.forEach((i) => (count = count + (+i[key] || 0)));

        return count;
    }

    return (
        <GridCard
            boxStyles={{
                minHeight: 1000,
                height: "fit-content",
            }}
            gridProps={{ xs: 12, md: 6 }}
        >
            <Stack spacing={12}>
                <Stack spacing={8}>
                    <Typography variant="h6">{label}</Typography>

                    <Box>
                        <Autocomplete
                            value={selectedBranch}
                            onChange={(e, value) => handleBranchSelect(value)}
                            disablePortal
                            id="combo-box-demo"
                            options={allBranches}
                            // sx={{ width: "50%" }}
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("likeForLike.enter_branch")}
                                />
                            )}
                        />
                    </Box>
                </Stack>

                <Stack spacing={8}>
                    <Stack spacing={5}>
                        <Box
                            display="flex"
                            alignItems="baseline"
                            justifyContent="space-between"
                        >
                            <Typography variant="h6" fontWeight="bold">
                                {t("likeForLike.today_sales")}:
                            </Typography>

                            <Typography variant="h6" fontWeight="bold">
                                {numberWithCommas(
                                    isOwner
                                        ? branchesSalesPeriods?.today?.total
                                        : salesPeriods["current date"]
                                ) || 0}{" "}
                                {t("common.currency")}
                            </Typography>
                        </Box>

                        <Box
                            display="flex"
                            alignItems="center"
                            gap={5}
                            flexWrap="wrap"
                        >
                            {branches.map((branch) => (
                                <Box display="flex" alignItems="center" gap={3}>
                                    <Box
                                        backgroundColor={branch.fill}
                                        borderRadius="50%"
                                        width="16px"
                                        height="16px"
                                    />

                                    <Typography fontSize="14px">
                                        {branch.name}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Stack>

                    {!isOwner && (
                        <Grid container flexWrap="nowrap">
                            <Grid item marginRight={8} xs={6}>
                                <Stack spacing={8}>
                                    <SalesCard
                                        label={t(
                                            "likeForLike.same_day_last_week"
                                        )}
                                        value={
                                            numberWithCommas(
                                                salesPeriods["previous week"]
                                            ) || 0
                                        }
                                    />
                                    <SalesCard
                                        label={t(
                                            "likeForLike.same_day_last_qur"
                                        )}
                                        value={
                                            numberWithCommas(
                                                salesPeriods["previous quarter"]
                                            ) || 0
                                        }
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={6}>
                                <Stack spacing={8}>
                                    <SalesCard
                                        label={t(
                                            "likeForLike.same_day_prev_month"
                                        )}
                                        value={
                                            numberWithCommas(
                                                salesPeriods["previous month"]
                                            ) || 0
                                        }
                                    />
                                    <SalesCard
                                        label={t(
                                            "likeForLike.same_day_last_year"
                                        )}
                                        value={
                                            numberWithCommas(
                                                salesPeriods["previous year"]
                                            ) || 0
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    )}

                    {isOwner && (
                        <Box>
                            <Box
                                display="flex"
                                width="100%"
                                height={120}
                                border="1px solid #E5E7EB"
                            >
                                <SalesCardWithBranchs
                                    label={t("likeForLike.same_day_last_week")}
                                    total={branchesSalesPeriods.prevWeek.total}
                                    chartData={
                                        branchesSalesPeriods.prevWeek.chart
                                    }
                                    containerProps={{
                                        borderRight:
                                            !isArabic && "1px solid #E5E7EB",
                                        borderLeft:
                                            isArabic && "1px solid #E5E7EB",
                                    }}
                                />
                                <SalesCardWithBranchs
                                    label={t("likeForLike.same_day_prev_month")}
                                    total={branchesSalesPeriods.prevMonth.total}
                                    chartData={
                                        branchesSalesPeriods.prevMonth.chart
                                    }
                                />
                            </Box>
                            <Box
                                display="flex"
                                width="100%"
                                height={120}
                                border="1px solid #E5E7EB"
                                // borderTop="none"
                            >
                                <SalesCardWithBranchs
                                    label={t("likeForLike.same_day_last_qur")}
                                    total={
                                        branchesSalesPeriods.prevQuarter.total
                                    }
                                    chartData={
                                        branchesSalesPeriods.prevQuarter.chart
                                    }
                                    containerProps={{
                                        borderRight:
                                            !isArabic && "1px solid #E5E7EB",
                                        borderLeft:
                                            isArabic && "1px solid #E5E7EB",
                                    }}
                                />
                                <SalesCardWithBranchs
                                    label={t("likeForLike.same_day_last_year")}
                                    total={branchesSalesPeriods.prevYear.total}
                                    chartData={
                                        branchesSalesPeriods.prevYear.chart
                                    }
                                />
                            </Box>
                        </Box>
                    )}
                </Stack>

                <Stack spacing={8}>
                    <PerformanceLineChart
                        title={t("common.sales")}
                        data={salesData}
                        yMax={highestTotalSales}
                        periods={{
                            percentage: "+2.4",
                            currentTotal: `${constants.currentCurrency} 00.00`,
                            prevTotal: `${constants.currentCurrency} 00.00`,
                        }}
                    />
                </Stack>
            </Stack>
        </GridCard>
    );
};

export { BranchDetails };
