import { useTranslation } from "react-i18next";
import {
    Box,
    Grid,
    useTheme,
    useMediaQuery,
    Typography,
    Stack,
} from "@mui/material";
import { useEffect, useState } from "react";

const GridCardHeader = ({ title }) => {
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

    return (
        <Typography
            fontWeight="bold"
            fontSize={isMobileView ? 16 : 20}
            color="#202020"
        >
            {title}
        </Typography>
    );
};

const GridCardFlatLabels = ({
    data,
    withSalesLabel,
    isFlat,
    withPercentage = true,
    isCenter,
}) => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const [labels, setLabels] = useState([]);

    useEffect(() => {
        setLabels(data?.sort((a, b) => b.value - a.value));
    }, [data]);

    return (
        <Box
            display="flex"
            gap={4}
            rowGap={isFlat && 4}
            paddingX={6}
            paddingY={3}
            flexWrap="wrap"
            width="100%"
            maxWidth="100%"
            overflow="visible"
            minHeight={45}
            justifyContent={isCenter && "center"}
        >
            {labels?.map((label) => (
                <Box
                    display="flex"
                    alignItems="center"
                    gap={3}
                    key={label.fill}
                >
                    <Box
                        backgroundColor={label.fill}
                        borderRadius="50%"
                        width="16px"
                        height="16px"
                    />

                    <Box display="flex" gap={2}>
                        <Typography fontSize="14px" textAlign="center">
                            {label.name}
                        </Typography>

                        {withSalesLabel && (
                            <Typography
                                fontSize="14px"
                                textAlign="center"
                                fontWeight="bold"
                                sx={{ mx: 1 }}
                            >
                                {label.sales}
                            </Typography>
                        )}

                        {withPercentage && (
                            <Typography fontSize="14px" textAlign="center">
                                {isArabic
                                    ? `(%${label.value})`
                                    : ` (${label.value}%)`}
                            </Typography>
                        )}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

const GridCardLabels = ({ data, withSalesLabel, isFlat }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

    const [labels, setLabels] = useState([]);

    useEffect(() => {
        setLabels(data?.sort((a, b) => b.value - a.value));
    }, [data]);

    return (
        <Stack
            direction={isFlat && "row"}
            spacing={isFlat ? 4 : isMobileView ? 0 : 1}
            rowGap={isFlat && 4}
            flex={1}
            paddingX={isMobileView ? 6 : 8}
            paddingY={3}
            // maxHeight={!isMobileView && "360px"}
            flexWrap={isMobileView ? "nowrap" : "wrap"}
            // flexWrap={"nowrap"}
            width={isFlat ? "100%" : "50%"}
            maxWidth={isFlat ? "100%" : "50%"}
            overflow="visible"
        >
            {labels?.map((label) => (
                <Box
                    display="flex"
                    alignItems="center"
                    gap={3}
                    key={label.fill}
                >
                    <Box
                        backgroundColor={label.fill}
                        borderRadius="50%"
                        width="16px"
                        height="16px"
                    />

                    <Box display="flex" gap={2}>
                        <Typography fontSize="14px" textAlign="center">
                            {label.name}
                        </Typography>

                        {withSalesLabel && (
                            <Typography
                                fontSize="14px"
                                textAlign="center"
                                fontWeight="bold"
                                sx={{ mx: 1 }}
                            >
                                {label.sales}
                            </Typography>
                        )}

                        <Typography fontSize="14px" textAlign="center">
                            {isArabic
                                ? `(%${label.value})`
                                : ` (${label.value}%)`}
                        </Typography>
                    </Box>

                    {/* <Typography fontSize="14px" textAlign="center" flex={1}>
                        {isArabic
                            ? `(%${label.value}) ${
                                  withSalesLabel ? label.sales : ""
                              } ${label.name} `
                            : `${label.name}  ${
                                  withSalesLabel ? label.sales : ""
                              } (${label.value}%)`}
                    </Typography> */}
                </Box>
            ))}
        </Stack>
    );
};

const GridCard = ({
    children,
    gutter,
    fullWidth = false,
    height = 360,
    padding = 6,
    boxStyles,
    gridProps,
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

    return (
        <Grid
            item
            xs={fullWidth ? 12 : 6}
            // marginRight={gutter && isArabic ? 0 : 6}
            {...gridProps}
        >
            <Box display="flex" minHeight="100%">
                <Box
                    width="100%"
                    height={height}
                    minHeight="100%"
                    backgroundColor="white"
                    borderRadius={5}
                    padding={padding}
                    sx={{ boxShadow: "0 1px 3px hsla(220,6%,43%,0.2)" }}
                    flex={1}
                    {...boxStyles}
                >
                    {children}
                </Box>

                {gutter && <Box width="24px"></Box>}
            </Box>
        </Grid>
    );
};

export { GridCard, GridCardHeader, GridCardLabels, GridCardFlatLabels };
