import { createContext, useEffect, useMemo, useState } from "react";
import { Stack, Box, Typography, Button } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Outlet, useNavigate } from "react-router-dom";
import { TopNavbar } from "../TopNavbar";
import { SideNavbar } from "../SideNavbar";
import {
    getToken,
    getUserStorage,
    setUserStorage,
} from "../../../common/utils";
import CachedIcon from "@mui/icons-material/Cached";
import { AuthServices } from "../../../Auth/services";
import { useTranslation } from "react-i18next";
import { FreeTrialAlert } from "../../../App/Owner/Settings";
import { ManagerViewAlert } from "../../../App/Owner/ManagerViewAlert";
import { PhoneModal } from "../PhoneModal/PhoneModal";
import { toast } from "react-toastify";
import { services } from "../../../App/manager/services";
import { constants } from "../../../config/constants";
import { differenceInMinutes } from "date-fns";

export const UserContext = createContext(null);

const SharedLayout = () => {
    const [openFreeTrial, setIsOpenFreeTrail] = useState(true);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const user = getUserStorage();
    const [userData, setUserData] = useState(user);
    const [isAuthenticated, setIsAuthenticated] = useState(!!user);
    const session = getToken();
    const navigate = useNavigate();

    const isManagerView = userData?.manager_view;
    const [isOpenPhoneModal, setIsOpenPhoneModal] = useState(!userData?.phone);

    const [resyncRequested, setResyncRequestd] = useState(false);
    const notify = () =>
        toast.info(
            "Your resync request is being processed, this might take several minutes!",
            {
                hideProgressBar: true,
            }
        );

    const [isConnected, setIsConnected] = useState(true);

    const { data, isFetching } = useQuery({
        queryKey: ["me"],
        queryFn: AuthServices.getCurrentUser,
        retry: false,
        enabled: !!session,
    });

    const { data: lastUpdate } = useQuery({
        queryKey: ["lastUpdate"],
        queryFn: AuthServices.lastUpdate,
        // retry: false,
        enabled: !!session,
        refetchOnWindowFocus: false,
    });

    const { mutate: resyncData, isLoading } = useMutation({
        mutationFn: services.resyncData,
        onSuccess: (res) => {
            if (res.data.error) {
                setResyncRequestd(false);
                localStorage.removeItem(constants.storage.lastResyncDate);
            }
        },
        onError: (err) => console.log(err),
    });

    const lastResyncDate = new Date(
        JSON.parse(localStorage.getItem(constants.storage.lastResyncDate))
    );

    const shouldDisableResync =
        differenceInMinutes(new Date(), lastResyncDate) < 15;

    const lastUpdateDate =
        lastUpdate?.data?.response?.last_date &&
        new Date(lastUpdate?.data?.response?.last_date).toLocaleString();

    const handleResync = () => {
        setResyncRequestd(true);
        localStorage.setItem(
            constants.storage.lastResyncDate,
            JSON.stringify(new Date())
        );
        notify();
        resyncData();
    };

    useEffect(() => {
        if (!session) navigate("/login");
    }, [session, navigate]);

    // useEffect(() => {
    //     if (data?.data?.response?.user) {
    //         setUserStorage(session, data?.data?.response?.user);
    //     }
    // }, [data, session]);

    if (
        data?.data?.response?.user &&
        !deepCompare(user, data?.data?.response?.user) &&
        !isFetching
    ) {
        setUserStorage(session, data?.data?.response?.user);
        setUserData(data?.data?.response?.user);
        setIsAuthenticated(true);
    }

    function deepCompare(obj1, obj2) {
        // Get the type of both objects
        const type1 = typeof obj1;
        const type2 = typeof obj2;

        // Check if both objects are non-null and objects
        if (
            type1 !== "object" ||
            obj1 === null ||
            type2 !== "object" ||
            obj2 === null
        ) {
            return obj1 === obj2;
        }

        // Get the keys of both objects
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // Check if both objects have the same number of keys
        if (keys1.length !== keys2.length) {
            return false;
        }

        // Check if all keys in obj1 are present in obj2 and their values are deeply equal
        for (const key of keys1) {
            if (
                !obj2.hasOwnProperty(key) ||
                !deepCompare(obj1[key], obj2[key])
            ) {
                return false;
            }
        }

        return true;
    }

    const contextValue = useMemo(
        () => ({
            userData,
        }),
        [userData]
    );

    useEffect(() => {
        if (isAuthenticated) {
            window.pendo.initialize({
                visitor: {
                    id: data?.data?.response?.user.id, // Required if user is logged in, default creates anonymous ID
                    email: data?.data?.response?.user.email, // Recommended if using Pendo Feedback, or NPS Email
                    // full_name:    // Recommended if using Pendo Feedback
                    // role:         // Optional

                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                },
            });
        }
    }, [isAuthenticated]);

    return (
        <Stack
            width="100%"
            minHeight="100vh"
            bgcolor="#f5f6f6"
            dir={isArabic ? "rtl" : "ltr"}
        >
            <UserContext.Provider value={contextValue}>
                {isAuthenticated && (
                    <>
                        <Box display="flex" position="relative">
                            <SideNavbar />

                            <Stack width="100%" overflow="hidden">
                                <TopNavbar />
                                <Box
                                    px={6}
                                    pt={2}
                                    display="flex"
                                    alignItems="center"
                                    gap={4}
                                >
                                    <Typography fontSize={14} color="#999999">
                                        {t("overview.last_update")} :{" "}
                                        {lastUpdateDate}
                                    </Typography>
                                    <Typography fontSize={14} color="#999999">
                                        |
                                    </Typography>
                                    <Button
                                        startIcon={<CachedIcon />}
                                        onClick={handleResync}
                                        disabled={
                                            shouldDisableResync ||
                                            resyncRequested
                                        }
                                        variant="text"
                                        sx={{
                                            textTransform: "capitalize",
                                            padding: 0,
                                            color: "#3498db",
                                        }}
                                    >
                                        {t("top_nav.resync")}
                                    </Button>
                                </Box>
                                <FreeTrialAlert
                                    closeAlert={() => setIsOpenFreeTrail(false)}
                                />
                                {isManagerView && <ManagerViewAlert />}
                                {isOpenPhoneModal && (
                                    <PhoneModal
                                        handleClose={() =>
                                            setIsOpenPhoneModal(false)
                                        }
                                    />
                                )}
                                <Outlet />
                            </Stack>
                        </Box>
                    </>
                )}
            </UserContext.Provider>
        </Stack>
    );
};

export { SharedLayout };
