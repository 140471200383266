import { Box, Divider, Stack, Typography } from "@mui/material";
import { GridCardHeader } from "../../../../common/components";
import { useTranslation } from "react-i18next";
import { AreaChart } from "../components/AreaChart";

const fakeData = [
    {
        name: "22 Aug",
        "Sales Forcast": 4000,
        "Profitability Forecast": 2400,
    },
    {
        name: "25 Aug",
        "Sales Forcast": 3000,
        "Profitability Forecast": 1398,
    },
    {
        name: "28 Aug",
        "Sales Forcast": 2000,
        "Profitability Forecast": 1800,
    },
    {
        name: "03 Sep",
        "Sales Forcast": 2780,
        "Profitability Forecast": 1908,
    },
    {
        name: "06 Sep",
        "Sales Forcast": 1890,
        "Profitability Forecast": 2800,
    },
    {
        name: "09 Sep",
        "Profitability Forecast": 2800,
    },
    {
        name: "15 Sep",
        "Profitability Forecast": 4300,
    },
];

const TotalSalesData = () => {
    const { t } = useTranslation();

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <GridCardHeader title="28-day Sales Forecast" />
                {/* <GridCardHeader title={t("forcast.total_sales_data")} /> */}
            </Box>

            <Divider />

            <Stack>
                <Box width="100%" height="340px" px={2} py={10}>
                    <AreaChart
                        data={fakeData}
                        key2="Sales Forcast"
                        key1="Profitability Forecast"
                    />
                </Box>

                <Box display="flex" alignItems="center" gap={6} px={6}>
                    <Box display="flex" alignItems="center" gap={3}>
                        <Box
                            width="16px"
                            height="16px"
                            backgroundColor="#14C594"
                            borderRadius="4px"
                        />

                        <Typography fontSize="16px" color="#202020">
                            Sales Forcast
                        </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" gap={3}>
                        <Box
                            width="16px"
                            height="16px"
                            backgroundColor="#DFDE7F"
                            borderRadius="4px"
                        />

                        <Typography fontSize="16px" color="#202020">
                            Profitability Forecast
                        </Typography>
                    </Box>
                </Box>
            </Stack>
        </Stack>
    );
};

export { TotalSalesData };
