import { Box, Divider, Stack, Typography } from "@mui/material";
import { GridCardHeader } from "../../../../common/components";
import { useTranslation } from "react-i18next";
import { AreaChart } from "../components/AreaChart";

const fakeData = [
    {
        name: "12:00 AM",
        "Profitability Forcast": 4000,
        "Sales Forcast": 2400,
    },
    {
        name: "04:00 AM",
        "Profitability Forcast": 3000,
        "Sales Forcast": 1398,
    },
    {
        name: "08:00 AM",
        "Profitability Forcast": 2000,
        "Sales Forcast": 1800,
    },
    {
        name: "12:00 PM",
        "Profitability Forcast": 2780,
        "Sales Forcast": 1908,
    },
    {
        name: "04:00 PM",
        "Profitability Forcast": 1890,
        "Sales Forcast": 2800,
    },
    {
        name: "08:00 PM",
        "Sales Forcast": 2800,
    },
    {
        name: "11:00 PM",
        "Sales Forcast": 4300,
    },
];

const TotalSalesData = () => {
    const { t } = useTranslation();

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={6}
                height={74}
            >
                <GridCardHeader title="Total Sales Data" />
                {/* <GridCardHeader title={t("forcast.total_sales_data")} /> */}

                <Box display="flex" alignItems="center" gap={3}>
                    <Box gap={2} display="flex" alignItems="center">
                        <Typography fontSize="16px" color="#475569">
                            Sales
                        </Typography>
                        <Typography
                            fontSize="16px"
                            color="#202020"
                            fontWeight="bold"
                        >
                            11,420.00 {t("common.currency")}
                        </Typography>
                    </Box>

                    <Divider flexItem orientation="vertical" height="20px" />

                    <Box gap={2} display="flex" alignItems="center">
                        <Typography fontSize="16px" color="#475569">
                            Profitability
                        </Typography>
                        <Typography
                            fontSize="16px"
                            color="#202020"
                            fontWeight="bold"
                        >
                            1,420.00 {t("common.currency")}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Divider />

            <Stack>
                <Box width="100%" height="340px" px={2} py={10}>
                    <AreaChart
                        data={fakeData}
                        key1="Sales Forcast"
                        key2="Profitability Forcast"
                    />
                </Box>

                <Box display="flex" alignItems="center" gap={6} px={6}>
                    <Box display="flex" alignItems="center" gap={3}>
                        <Box
                            width="16px"
                            height="16px"
                            backgroundColor="#DFDE7F"
                            borderRadius="4px"
                        />

                        <Typography fontSize="16px" color="#202020">
                            Sales Forecast
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={3}>
                        <Box
                            width="16px"
                            height="16px"
                            backgroundColor="#14C594"
                            borderRadius="4px"
                        />

                        <Typography fontSize="16px" color="#202020">
                            Profitability Forecast
                        </Typography>
                    </Box>
                </Box>
            </Stack>
        </Stack>
    );
};

export { TotalSalesData };
