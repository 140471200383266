import { FormControl, InputBase, MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

const CustomSelectCSS = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        position: "relative",
        backgroundColor: "white",
        border: "1px solid #ced4da",
        padding: "5px 16px 6px 12px",
        fontSize: 14,
        borderRadius: "16px",
        borderRight: 0,
        // "&:focus": {
        //     borderRadius: "16px",
        // },
    },
}));

const ButtonSelect = ({ value, onChange, options }) => {
    return (
        <FormControl
            sx={{
                width: 150,
                backgroundColor: "white",
                height: 36,
                borderRadius: "16px",
            }}
        >
            <Select
                value={value}
                onChange={onChange}
                displayEmpty
                input={<CustomSelectCSS />}
                IconComponent={ExpandMoreIcon}
            >
                {options.map((option) => (
                    <MenuItem key={option} value={option.id}>
                        {option.label || option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export { ButtonSelect };
