import { useState } from "react";
import {
    Box,
    Typography,
    Avatar,
    Stack,
    Popover,
    List,
    ListItemButton,
    ListItemText,
    Divider,
    useTheme,
    useMediaQuery,
    Button,
    Modal,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { constants } from "../../../config/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserStorage } from "../../utils";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 10,
    borderRadius: 4,
};

const TopNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.only("xs"));

    const navigate = useNavigate();
    const user = getUserStorage();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.removeItem(constants.storage.token);
        sessionStorage.removeItem(constants.storage.token);
        localStorage.removeItem(constants.storage.user);
        sessionStorage.removeItem(constants.storage.token);

        navigate("/login");
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const isManagerView = user.manager_view;

    return (
        <Box
            height={isMobileView ? "70px" : "90px"}
            width="100%"
            bgcolor="white"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingX={isMobileView ? 3 : 8}
        >
            <Typography variant="h5" color="white" fontWeight="bold">
                BALLURH
            </Typography>

            <Box
                display="flex"
                gap={5}
                alignItems="center"
                borderColor="white"
                height="100%"
                paddingLeft={4}
                sx={{ cursor: "pointer" }}
                onClick={handleClick}
            >
                <Stack spacing={0} flex={1}>
                    <Typography color="#1F2938" fontSize="18px">
                        {user?.first_name}
                    </Typography>
                    {isManagerView && (
                        <Typography
                            color="#94A3B8"
                            fontSize="16px"
                            align="right"
                        >
                            {user?.branch[0]?.name}
                        </Typography>
                    )}
                </Stack>

                <Divider orientation="vertical" sx={{ height: "28px" }} />

                <Box display="flex" alignItems="center" gap={3}>
                    <Avatar src={`${constants.API}/${user?.merchant?.logo}`} />

                    <KeyboardArrowDownIcon
                        sx={{ color: "black", fontSize: 32 }}
                    />
                </Box>
            </Box>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: isArabic ? "left" : "center",
                }}
                sx={{ marginTop: 1 }}
            >
                <List sx={{ width: "220px" }}>
                    <ListItemButton
                        sx={{ height: 32 }}
                        onClick={() => {
                            handleClose();
                            navigate("/account");
                        }}
                    >
                        <ListItemText
                            primaryTypographyProps={{
                                fontSize: 14,
                                textAlign: isArabic ? "right" : "left",
                            }}
                            primary={t("top_nav.account")}
                        />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ height: 32 }}
                        onClick={() => {
                            handleClose();
                            navigate("/integrations");
                        }}
                    >
                        <ListItemText
                            primaryTypographyProps={{
                                fontSize: 14,
                                textAlign: isArabic ? "right" : "left",
                            }}
                            primary={t("top_nav.integrations")}
                        />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ height: 32 }}
                        onClick={() => setIsOpen(true)}
                    >
                        <ListItemText
                            primaryTypographyProps={{
                                fontSize: 14,
                                textAlign: isArabic ? "right" : "left",
                            }}
                            primary={t("top_nav.log_out")}
                        />
                    </ListItemButton>
                </List>
            </Popover>

            {isOpen && (
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Stack spacing={5}>
                            <Stack>
                                <Typography
                                    id="modal-modal-description"
                                    sx={{ mt: 2 }}
                                    textAlign={isArabic ? "right" : "left"}
                                >
                                    {t("common.logout")}
                                </Typography>
                            </Stack>
                        </Stack>

                        <Box
                            display="flex"
                            mt={10}
                            justifyContent="flex-end"
                            gap={5}
                        >
                            <Button
                                variant="outlined"
                                sx={{
                                    textTransform: "capitalize",
                                    borderRadius: "6px",
                                }}
                                onClick={() => setIsOpen(false)}
                            >
                                {t("common.cancel")}
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    textTransform: "capitalize",
                                    borderRadius: "6px",
                                    width: "fit-content",
                                    color: "white",
                                    backgroundColor: "#5BCFDE",
                                    border: "1px solid #EEEFF4",
                                    "&:hover": {
                                        border: "1px solid #EEEFF4",
                                        backgroundColor: "#5BCFDE",
                                    },
                                }}
                                onClick={handleLogout}
                            >
                                {t("top_nav.log_out")}
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
        </Box>
    );
};

export { TopNavbar };
