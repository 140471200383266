import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { uniqBy } from "lodash";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
// import IosShareIcon from "@mui/icons-material/IosShare";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import {
    ButtonSelect,
    GridCard,
    RangePicker,
} from "../../../../common/components";
import { CylinderBar } from "./CylinderBar";
import { services } from "../../services";
import { getToken, getUserStorage } from "../../../../common/utils";
import {
    PuzzleScatterChart,
    StarScatterChart,
    TurtleScatterChart,
} from "./Matrix";
import { HorseScatterChart } from "./Matrix/HorseScatterChart";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { constants } from "../../../../config/constants";
import { toast } from "react-toastify";
import { ExportButton } from "../../../../common/components/Export/Export";

const classesColors = {
    Horse: "green",
    Turtle: "gray",
    Puzzle: "lightBlue",
    Star: "darkBlue",
};

const classesPositions = {
    Star: { x: 1, y: 1 },
    Horse: { x: -1, y: 1 },
    Turtle: { x: -1, y: -1 },
    Puzzle: { x: 1, y: -1 },
};

const ClassesLegend = () => {
    const { t } = useTranslation();

    return (
        <Box display="flex" alignItems="center" gap={4} flexWrap="wrap">
            <Box display="flex" alignItems="center" gap={3}>
                <Box
                    width={20}
                    height={20}
                    borderRadius="50%"
                    backgroundColor="#06262D"
                />

                <Typography fontSize={17} color="#00010A">
                    {/* {t("menu.stars")} */}
                    Stars
                </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap={3}>
                <Box
                    width={20}
                    height={20}
                    borderRadius="50%"
                    backgroundColor="#5BCFDE"
                />

                <Typography fontSize={17} color="#00010A">
                    {/* {t("menu.puzzles")} */}
                    Puzzles
                </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap={3}>
                <Box
                    width={20}
                    height={20}
                    borderRadius="50%"
                    backgroundColor="#9FE3B9"
                />

                <Typography fontSize={17} color="#00010A">
                    {/* {t("menu.horses")} */}
                    Horses
                </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap={3}>
                <Box
                    width={20}
                    height={20}
                    borderRadius="50%"
                    backgroundColor="#D5D5D5"
                />

                <Typography fontSize={17} color="#00010A">
                    {/* {t("menu.turtles")} */}
                    Turtles
                </Typography>
            </Box>
        </Box>
    );
};

const Insights = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const userBranches =
        isOwner && !user.manager_view ? user?.merchant?.branches : user?.branch;
    const allBranches = userBranches.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));

    const isManagerView = user.manager_view;
    const allBranchesOption = { id: "all", label: t("common.all_branches") };
    const [selectedBranch, setSelectedBranch] = useState(
        isManagerView ? allBranches[0].id : allBranchesOption.id
    );

    const [period, setPeriod] = useState({
        startDate: null,
        endDate: format(new Date(), "y-M-d"),
    });

    const [girdData, setGridData] = useState([]);
    const [donughtData, setDonughtData] = useState([]);

    const [overviewPDF, setOverviewPDF] = useState({});
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

    const { mutate: getMenuProducts, isLoading } = useMutation({
        mutationFn: services.getMenuEngineeringProducts,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                refineData(res.data.response.filter((p) => !!p.class));

                setOverviewPDF({
                    refine_data: res.data.response.filter((p) => !!p.class),
                    period,
                });
            }
            if (res.data.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    function refineData(data) {
        const classess = {
            Star: [],
            Horse: [],
            Turtle: [],
            Puzzle: [],
        };

        const all = [];

        data.forEach((product) => {
            const coordinations = classesPositions[product.class];

            all.push({
                id: product.product_id,
                popularity: +(+product.popularity * coordinations.y).toFixed(2),
                profitablility: +(
                    +product.profitability * coordinations.x
                ).toFixed(2),
                color: classesColors[product.class],
                name: product.product_name,
            });

            classess[product.class] = [
                ...classess[product.class],
                {
                    id: product.product_id,
                    popularity: +product.popularity,
                    profitablility: +product.profitability,
                    color: classesColors[product.class],
                    name: product.product_name,
                },
            ];
        });

        setDonughtData(
            Object.keys(classess)
                .map((classKey) => {
                    if (!!classess[classKey].length) {
                        const perc = +(
                            (classess[classKey].length / data.length) *
                            100
                        ).toFixed(2);

                        return {
                            name: `${classKey}`,
                            key: `${classKey}s`,
                            value: perc,
                            fill: classesColors[classKey],
                            imgPath: `/${classKey.toLowerCase()}_class.svg`,
                        };
                    } else
                        return {
                            name: `${classKey}`,
                            key: `${classKey}s`,
                            value: 0,
                            fill: classesColors[classKey],
                            imgPath: `/${classKey.toLowerCase()}_class.svg`,
                        };
                })
                .filter((i) => i)
        );

        // setGridData(uniqBy(all, (p) => p.id));
        setGridData(classess);
    }

    const handleDatesChange = ({ startDate, endDate }) => {
        if (startDate !== "1970-1-1") {
            setPeriod({ startDate, endDate });

            getMenuProducts({
                from_date: startDate,
                till_date: endDate,
                branch_id: selectedBranch,
            });
        }
    };

    const handleBranchChange = (e) => {
        const newSelectedBranchValue = e.target.value;
        setSelectedBranch(newSelectedBranchValue);

        getMenuProducts({
            from_date: period.startDate,
            till_date: period.endDate,
            branch_id: newSelectedBranchValue,
        });
    };

    useEffect(() => {
        if (period.startDate) {
            getMenuProducts({
                from_date: new Date("1/1/2000").toLocaleDateString("en"),
                till_date: format(new Date(), "y-M-d"),
                branch_id: selectedBranch,
            });
        }
    }, []);

    const getClassData = (key) => donughtData.find((c) => c.name === key);

    const cylindarsBars = donughtData.length
        ? [
              getClassData("Star"),
              getClassData("Puzzle"),
              getClassData("Horse"),
              getClassData("Turtle"),
          ]
        : [];

    useEffect(() => {
        window.pendo.pageLoad({
            name: "Menu Insights Page",
        });
    }, []);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Stack spacing={0}>
                <Typography fontSize={30} fontWeight="bold">
                    {t("menu.insights")}
                </Typography>
            </Stack>

            <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center" gap={3}>
                    <RangePicker
                        onChange={(values) => handleDatesChange(values)}
                    />

                    {isLoading && (
                        <CircularProgress color="primary" size={20} />
                    )}
                </Box>

                <Box display="flex" alignItems="center" gap={3}>
                    {!isManagerView && (
                        <ButtonSelect
                            value={selectedBranch}
                            onChange={handleBranchChange}
                            options={[allBranchesOption, ...allBranches]}
                        />
                    )}

                    <ExportButton
                        overviewPDF={overviewPDF}
                        pageName="MenuInsightsPDF"
                        isLoading={isLoading}
                    />
                </Box>
            </Box>

            <Grid container rowGap={6}>
                <GridCard
                    padding={0}
                    fullWidth
                    height="fit-content"
                    boxStyles={{ minHeight: 535 }}
                >
                    <Stack>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            padding={6}
                            height={74}
                        >
                            <Typography
                                fontWeight="bold"
                                fontSize={20}
                                color="#202020"
                            >
                                {t("menu.product_classes")}
                            </Typography>

                            <Box display="flex" alignItems="center">
                                <ClassesLegend />
                            </Box>
                        </Box>

                        <Divider />

                        <Grid
                            container
                            flexWrap="wrap"
                            alignItems="center"
                            display="flex"
                            paddingTop={8}
                        >
                            <Grid xs={12} md={6} height="100%">
                                <Box
                                    display="flex"
                                    gap={10}
                                    alignItems="center"
                                    height="100%"
                                    justifyContent="center"
                                    paddingX={12}
                                >
                                    {cylindarsBars.map((classData) => (
                                        <CylinderBar
                                            color={classData.fill}
                                            perc={classData.value}
                                            imgPath={classData.imgPath}
                                        />
                                    ))}
                                </Box>
                            </Grid>

                            <Grid xs={12} md={6} height="100%">
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    height="100%"
                                    padding={12}
                                    position="relative"
                                    top="24px"
                                >
                                    <img
                                        src={
                                            isArabic
                                                ? "/classes_ar.svg"
                                                : "/classes.png"
                                        }
                                        width={540}
                                        alt="classes"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                </GridCard>

                <GridCard
                    padding={0}
                    fullWidth
                    boxStyles={{ minHeight: "920px" }}
                >
                    <Stack position="relative">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            padding={6}
                            height={74}
                        >
                            <Typography
                                fontWeight="bold"
                                fontSize={20}
                                color="#202020"
                            >
                                {t("menu.product_matrix")}
                            </Typography>
                        </Box>

                        <Divider />

                        <Box
                            display="flex"
                            width="100%"
                            padding={0}
                            paddingLeft={2}
                            paddingTop={8}
                        >
                            <Box alignSelf="center">
                                <Typography
                                    fontSize={22}
                                    sx={{
                                        transform: "rotate(-90deg)",
                                        width: "32px",
                                    }}
                                >
                                    {t("menu.profitability")}
                                </Typography>
                            </Box>
                            <Stack display="flex" alignItems="center" gap={4}>
                                <Typography fontSize={18}>
                                    {t("menu.high")}
                                </Typography>

                                <div
                                    style={{
                                        height: "94%",
                                        width: "2px",
                                        backgroundColor: "black",
                                        position: "relative",
                                        flex: 1,
                                    }}
                                >
                                    <ArrowDropUpIcon
                                        sx={{
                                            position: "absolute",
                                            top: -12,
                                            left: -11,
                                        }}
                                    />
                                </div>
                            </Stack>

                            <Stack padding={6} spacing={8} flex={1}>
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <Box
                                            paddingRight={isArabic ? 0 : 6}
                                            paddingLeft={isArabic ? 6 : 0}
                                        >
                                            <StarScatterChart
                                                data={girdData?.Star}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <PuzzleScatterChart
                                            data={girdData?.Puzzle}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <Box
                                            paddingRight={isArabic ? 0 : 6}
                                            paddingLeft={isArabic ? 6 : 0}
                                        >
                                            <HorseScatterChart
                                                data={girdData?.Horse}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TurtleScatterChart
                                            data={girdData?.Turtle}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>

                        <Box
                            paddingLeft={10}
                            paddingRight={8}
                            paddingTop={2}
                            // dir="rtl"
                            display="flex"
                            alignItems="center"
                            gap={4}
                        >
                            <Typography fontSize={18}>
                                {t("menu.low")}
                            </Typography>

                            <div
                                style={{
                                    width: "100%",
                                    height: "2px",
                                    backgroundColor: "black",
                                    position: "relative",
                                }}
                            >
                                {isArabic ? (
                                    <ArrowLeftIcon
                                        sx={{
                                            position: "absolute",
                                            top: -11,
                                            left: -14,
                                        }}
                                    />
                                ) : (
                                    <ArrowRightIcon
                                        sx={{
                                            position: "absolute",
                                            top: -11,
                                            right: -14,
                                        }}
                                    />
                                )}
                            </div>
                        </Box>

                        <Box alignSelf="center">
                            <Typography fontSize={22}>
                                {t("menu.popularity")}
                            </Typography>
                        </Box>

                        <Typography
                            fontSize={18}
                            position="relative"
                            top={-36}
                            right={isArabic ? -45 : 45}
                            align={isArabic ? "left" : "right"}
                        >
                            {t("menu.high")}
                        </Typography>
                    </Stack>
                </GridCard>
            </Grid>
        </Stack>
    );
};

export { Insights };
